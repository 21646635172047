import React from "react";
import { observer } from "mobx-react";
import { Step } from "./Components/Step";
import ImageStep from "./Components/ImageStep";
import GridStep from "./Components/GridStep";

import CountUp from "react-countup";
import SummaryStep from "./Components/SummaryStep";
import PdfStep from "./Components/PdfStep";
import CompleteStep from "./Components/CompleteStep";
import PaymentStep from "./Components/PaymentStep";
import { Store } from "./store";
import "./App.css";
import { Translation } from "./Translation";
import { appConfig, getLogo } from "./config";
import { Loading } from "./Components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import SmoothScrolling from "./Helpers/smootScrolling";

@observer
class App extends React.Component {
  store = new Store();

  componentDidMount() {
    const { slug, lang } = this.props.match.params;

    // get path
    this.store.init(slug, lang);
  }

  render() {
    const { page, data } = this.store;

    if (page.loading) return <Loading />;

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a href={appConfig.locale.url}>
              <img src={getLogo(appConfig.locale.key)} className="logo" />
            </a>
          </div>
          <div
            className="steps"
            style={page.hideSteps ? { display: "none" } : null}
          >
            {!this.store.page.completed && (
              <React.Fragment>
                {data.steps.map((step, i) => (
                  <Step
                    key={i}
                    order={i + 1}
                    visited={i <= page.stepIndex}
                    selected={i == page.stepIndex}
                    onSelected={() => {
                      this.store.onStepSelected(step, i);
                    }}
                    title={step[this.store.langify("title")]}
                  />
                ))}
                <Step
                  order={page.summaryStep}
                  visited={page.summaryStep <= page.stepIndex}
                  selected={page.summaryStep <= page.stepIndex}
                  onSelected={() => {
                    this.store.onStepSelected({}, page.summaryStep);
                  }}
                  title={this.store.page.lang == "en" ? "SUMMARY" : "ÖZET"}
                />
              </React.Fragment>
            )}
          </div>
        </div>
        <div
          className="mobile-title"
          dangerouslySetInnerHTML={{ __html: data.name }}
        ></div>
        <div className="content">
          {page.completeStep != page.stepIndex && (
            <React.Fragment>
              {!page.hideSteps &&
                data.steps.map((step, i) => (
                  <div
                    key={i}
                    className={`step-content-inner visible-mobile ${
                      i == page.stepIndex &&
                      "flex animate__animated animate__fadeIn"
                    }`}
                    data-title={step.title}
                  >
                    <div className="title">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10 indicator2"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        color="red"
                      >
                        <path
                          fill="currentColor"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                      <span>{step[this.store.langify("title")]}</span>
                    </div>
                    {step.type == "Image" && (
                      <ImageStep store={this.store} step={step} data={data} />
                    )}
                    {step.type == "Grid" && (
                      <GridStep store={this.store} step={step} />
                    )}
                  </div>
                ))}
              <div
                id="step-summary"
                className={`step-content-inner visible-mobile ${
                  page.summaryStep == page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                }`}
                data-title="SUMMARY"
              >
                <div className="title" id="summary">
                  {this.store.page.lang == "en" ? "SUMMARY" : "ÖZET"}
                </div>
                <SummaryStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${
                  page.pdfStep == page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                }`}
                data-title="Pdf"
              >
                <div className="title" id="pdf">
                  Pdf
                </div>
                <PdfStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${
                  page.paymentStep == page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                }`}
                data-title="Payment"
              >
                <div className="title" id="payment">
                  Payment
                </div>
                <PaymentStep store={this.store} />
              </div>
            </React.Fragment>
          )}

          {page.completeStep == page.stepIndex && (
            <div
              className={`step-content-inner ${"flex animate__animated animate__fadeIn"}`}
              data-title="Complete"
            >
              <CompleteStep store={this.store} />
            </div>
          )}
        </div>
        <div
          className="footer"
          style={{
            display: page.completeStep == page.stepIndex ? "none" : "flex",
          }}
        >
          {!this.store.page.completed && (
            <React.Fragment>
              <div className="price-summary">
                <div className="payment-options">
                  <FontAwesomeIcon
                    className="indicator2"
                    icon={faChevronRight}
                    color="red"
                  />
                  <span>
                    PURCHASE <strong>PRICE:</strong>
                  </span>
                </div>
                <div className="price-display">
                  <CountUp
                    className="price-text orbitron"
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix={appConfig.locale.symbol}
                    duration={1}
                    start={this.store.lastPrice}
                    end={this.store.summary.purchase_price}
                  />
                </div>
                <div className="buttons_mobile">
                  <div className="modelSelector__button dark mobile">
                    <a
                      onClick={() => {
                        this.store.onStepSelected({}, this.store.page.pdfStep);

                        setTimeout(() => {
                          SmoothScrolling.scrollTo("step-summary");
                        }, 400);
                      }}
                    >
                      SHOW SUMMARY
                    </a>
                  </div>
                </div>
              </div>
              <div className="delivery">
                <div className="button-wrapper">
                  {page.stepIndex == page.summaryStep && (
                    <React.Fragment>
                      <div className="modelSelector__button dark">
                        <a
                          onClick={() =>
                            this.store.onStepSelected(
                              {},
                              this.store.page.pdfStep
                            )
                          }
                        >
                          <Translation
                            t="button_pdf"
                            lang={this.store.page.lang}
                          />
                        </a>
                      </div>
                      {appConfig.locale.has_payment && (
                        <a
                          className="button red-button small animate__animated animate__fadeInUp"
                          onClick={() =>
                            this.store.onStepSelected(
                              {},
                              this.store.page.paymentStep
                            )
                          }
                        >
                          <Translation
                            t="button_buy"
                            lang={this.store.page.lang}
                          />
                        </a>
                      )}
                    </React.Fragment>
                  )}

                  {page.stepIndex == page.pdfStep && (
                    <div className="modelSelector__button dark">
                      <a
                        onClick={(e) => {
                          var form = document.getElementById("pdf_form");

                          if (form.reportValidity()) {
                            this.store.onPdfSubmit(e);
                          }
                        }}
                      >
                        <Translation
                          t="button_pdf"
                          lang={this.store.page.lang}
                        />
                      </a>
                    </div>
                  )}

                  {page.stepIndex < page.summaryStep && (
                    <div className="modelSelector__button">
                      <a onClick={this.store.nextStep}>
                        <Translation lang={this.store.page.lang} t="next" />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default App;
