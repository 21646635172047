import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const Step = (x) => {
  return (
    <div
      className={`step ${x.visited && "step-visited"} ${
        x.selected && "step-active"
      }`}
      onClick={x.onSelected}
    >
      {x.selected ? (
        <FontAwesomeIcon
          className="indicator2"
          icon={faChevronRight}
          color="red"
        />
      ) : (
        <span style={{ display: "inline-block", width: 20 }}></span>
      )}
      {x.title}
    </div>
  );
};
