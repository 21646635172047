import React from "react";
import { appConfig } from "../config";
import { convertCurrency } from "../helpers";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import CompleteStep from "./CompleteStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

@observer
class OptionWithImage extends React.Component {
  render() {
    const { type, option, group, store } = this.props;

    return (
      <div
        className={`option ${
          type == "singleImage" ? "option-radio-image" : "option-checkbox-image"
        } ${option.selected ? "option-selected" : ""} ${
          option.image_top ? "image-top" : ""
        }`}
        style={{ display: option.hidden ? "none" : "" }}
        onClick={() => store.selectOption(option, group)}
      >
        {option.picture && (
          <div className="option-image">
            <img
              src={`${appConfig.api}${option.picture.url}`}
              className="animate__animated animate__fadeIn"
            />
          </div>
        )}
        <div className="option-content">
          <div className="option-title">{option[store.langify("title")]}</div>
          {option.description && (
            <div className="option-description">
              <pre>{option[store.langify("description")]}</pre>
            </div>
          )}
          <div className="option-price">
            {convertCurrency(option.price, true)}
            <span
              className={`option-selector ${option.selected && "selected"}`}
            >
              <span className="tick"></span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

@observer
class OptionWithoutImage extends React.Component {
  render() {
    const { type, option, group, store } = this.props;

    return (
      <div
        className={`option ${
          type == "single" ? "option-radio" : "option-checkbox"
        } ${option.selected ? "option-selected" : ""}`}
        style={{ display: option.hidden ? "none" : "block" }}
        onClick={() => store.selectOption(option, group)}
      >
        <div className="option-title">{option.description}</div>
        <div className="option-radio-description">{option.title}</div>
        <div className="option-price">
          {convertCurrency(option.price, true)}
          <span className={`option-selector ${option.selected && "selected"}`}>
            <span className="tick"></span>
          </span>
        </div>
      </div>
    );
  }
}

@observer
class OptionAdditional extends React.Component {
  render() {
    const { type, option, group, store } = this.props;

    return (
      <div
        className={`option option-additional ${
          option.selected ? "option-selected" : ""
        }`}
        style={{ display: option.hidden ? "none" : "" }}
      >
        <div className="option-additional-title">
          SPECIFY {group.title} HERE{" "}
          <FontAwesomeIcon icon={faChevronDown} color="red" />
        </div>
        <div className="option-content">
          <div className="option-title">
            <input
              type="text"
              className="option-additional-text"
              value={option.specified_value}
              placeholder={`ENTER YOUR DESIRED ${group.title}`}
              onClick={(e) => {
                if (!option.selected) {
                  store.selectOption(option, group);
                }
              }}
              onChange={(e) => {
                option.specified_value = e.target.value;
                option.title = `SPECIFIED ${group.title} ${e.target.value}`;
              }}
            />
          </div>
          <div
            className="option-price"
            onClick={() => store.selectOption(option, group)}
          >
            {convertCurrency(option.price, true)}
            <span
              className={`option-selector ${option.selected && "selected"}`}
            >
              <span className="tick"></span>
            </span>
          </div>
        </div>

        {option.description && (
          <div className="option-description">
            <pre>{option[store.langify("description")]}</pre>
          </div>
        )}
      </div>
    );
  }
}

@observer
class ImageStep extends React.Component {
  checkRelated = (step, group) => {
    const other_options = step.option_groups.flatMap((x) =>
      toJS(x.options).filter((o) => o.option_group != group.id)
    );

    const found = other_options.find(
      (x) => x.related && x.related.option_group == group.id
    );

    if (found && !found.selected) return false;

    return true;
  };

  render() {
    const { store, step, data } = this.props;

    return (
      <div className="step-image-content">
        <div
          className="image"
          style={{
            backgroundImage: data.picture
              ? `url(${appConfig.api}${data.picture.url})`
              : "url(https://via.placeholder.com/1920x1080)",
          }}
        ></div>

        <div className="options-wrapper">
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: data.name }}
          ></div>

          <div className="option-groups">
            {step.option_groups.map(
              (group, i) =>
                this.checkRelated(step, group) && (
                  <React.Fragment key={i}>
                    <div className={`option-group ${group.type}`}>
                      {!group.hide_title && (
                        <div className="option-group-title">
                          <FontAwesomeIcon
                            className="indicator2"
                            icon={faChevronRight}
                            color="red"
                          />
                          <span>
                            <strong className="uc">{group.title}</strong>{" "}
                            SELECTION
                          </span>
                        </div>
                      )}

                      {group.description && (
                        <div className="option-maindescription" style={{ fontSize: 12, padding: 15 }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: group.description,
                            }}
                          ></div>
                        </div>
                      )}

                      <div className={`options`}>
                        {group.options.map((option, j) =>
                          option.additional ? (
                            <OptionAdditional
                              key={j}
                              type={group.type}
                              group={group}
                              option={option}
                              store={store}
                            />
                          ) : group.type == "singleImage" ||
                            group.type == "multiImage" ? (
                            <OptionWithImage
                              key={j}
                              type={group.type}
                              group={group}
                              option={option}
                              store={store}
                            />
                          ) : (
                            <OptionWithoutImage
                              key={j}
                              type={group.type}
                              group={group}
                              option={option}
                              store={store}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ImageStep;
