export default {
  hello: { en: "Hello", tr: "Merhaba" },
  next: { en: "NEXT", tr: "İLERİ" },
  button_pdf: { en: "SEND ME PDF", tr: "PDF GÖNDER" },
  button_buy: { en: "BUY NOW", tr: "HEMEN SATIN AL" },
  footer_purchase_price: { en: "Purchase Price", tr: "Satış Fiyatı" },
  footer_estimate_payment: { en: "Estimate Payment", tr: "Tahmini Ödeme" },
  footer_estimate_delivery: {
    en: "Estimate Delivery<br />6-8 Weeks",
    tr: "Tahmini Teslim Süresi<br />6-8 Hafta",
  },
  summary_title: {
    en: "Your [model] is ready to go!",
    tr: "[model] hazır!",
  },
  summary_standards: {
    en: "STANDARDS",
    tr: "STANDART DONANIM",
  },
  summary_upgrade: {
    en: "UPGRADES & OPTIONS",
    tr: "EKLENTİLER VE SEÇENEKLER",
  },

  form_details: {
    en: "My Details",
    tr: "Kişisel Bilgilerim"
  },
  form_first_name: {
    en: "First Name",
    tr: "İsim"
  },
  form_last_name: {
    en: "Last Name",
    tr: "Soyisim"
  },
  form_email: {
    en: "Email Address",
    tr: "E-Posta Adresi"
  },
  form_phone: {
    en: "Phone Number",
    tr: 'Telefon Numarası'
  },
  form_boating: {
    en: "Where are you boating?",
    tr: "Seyir yaptığınız yer?"
  }
};
