import React from "react";
import { observer } from "mobx-react";
import { appConfig } from "../config";
import { ZohoForm } from "./ZohoForm";

@observer
class CompleteStep extends React.Component {
  render() {
    const { store } = this.props;

    return (
      <div className="step-complete-content">
        <div
          className="image"
          style={{
            backgroundImage:
              store.data.picture &&
              `url(${appConfig.api}${store.data.picture.url})`,
          }}
        ></div>
        <div className="complete-content">
          {store.page.completionType == "pdf" &&
            (store.page.lang == "en" ? (
              <React.Fragment>
                <ZohoForm data={store.summary} />
                <div style={{ display: "none" }}>
                  <h2>CHECK YOUR INBOX!</h2>
                  <h3>
                    A PDF OF YOUR{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: store.data.name }}
                    ></span>{" "}
                    WILL BE WAITING FOR YOU.
                  </h3>
                  <h3>#{store.page.order_number}</h3>
                  <p className="text">
                    Once you have received the PDF of your build and have any
                    questions simple reply to the email and a{" "}
                    {appConfig.locale.name} team member will respond within an
                    hour.
                  </p>
                  <p className="text-bold">
                    Thank you for your enquiry, we look forward to building your
                    beautiful new VanDutch.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <div className="modelSelector__button dark">
                      <a href={appConfig.locale.url}>BACK TO HOME PAGE</a>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h2>E-POSTA KUTUNUZU KONTROL EDİN!</h2>
                <h3>
                  TASARLADIĞINIZ {store.data.name} MODEL PDF'İ SİZİ BEKLİYOR.
                </h3>
                <h3>#{store.page.order_number}</h3>
                <p className="text">
                  Size ulaştırılan PDF mailine, sorularınızı iletebilirsiniz.
                  {appConfig.locale.name} ekibimiz en kısa sürede sorularınıza
                  yanıt verecektir.
                </p>
                <p className="text-bold">
                  İlginiz için teşekkür ederiz. Yeni model SaxDor teknelerimiz
                  için web sitemizi ziyaret edebilirsiniz.
                </p>
                <div>
                  <a
                    href={appConfig.locale.url}
                    className="button black-button wide"
                  >
                    ANASAYFA
                  </a>
                </div>
              </React.Fragment>
            ))}
          {store.page.completionType == "payment" && (
            <React.Fragment>
              <h2>SUCCESS!</h2>
              <h3>YOUR {store.data.name} HAS BEEN ORDERED!</h3>
              <h3>#{store.page.order_number}</h3>
              <p className="text">
                You will be receiving an email from your Personal VanDutch Sales
                Representative, with your Purchase agreement as well as more
                information on what to expect next.
              </p>
              <p className="text-bold">
                Thanks for your trust and we look forward to building your
                beautiful new VanDutch.
              </p>

              <div className="modelSelector__button dark">
                <a href={appConfig.locale.url}>BACK TO HOME PAGE</a>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default CompleteStep;
