import React from "react";
import { observer } from "mobx-react";
import { Store } from "./store";
import { appConfig, getLogo } from "./config";
import "./App.css";
import Logo from "./Logo/VanDutch.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import SmoothScrolling from "./Helpers/smootScrolling";

@observer
class Model extends React.Component {
  render() {
    return (
      <div
        className={`modelSelector__item ${
          this.props.store.selectedModel &&
          this.props.store.selectedModel.id == this.props.model.id &&
          "selected"
        }`}
        onClick={() => {
          this.props.store.selectModel(this.props.model);

          setTimeout(() => {
            SmoothScrolling.scrollTo("content");
          }, 400);
        }}
      >
        <div className="modelSelector__image">
          <img src={appConfig.api + this.props.model.cover.url} />
        </div>
        <div className="modelSelector__name">
          <FontAwesomeIcon
            className="indicator"
            icon={faChevronRight}
            color="red"
          />
          <span
            dangerouslySetInnerHTML={{ __html: this.props.model.name }}
          ></span>
        </div>
      </div>
    );
  }
}

@observer
class Tabs extends React.Component {
  state = {
    selected: 0,
  };

  componentDidMount() {
    // console.log(this.props.items);
  }

  selectTab(i) {
    if (i == this.state.selected) {
      this.setState({ selected: -1 });
    } else {
      this.setState({ selected: i });
    }
  }

  render() {
    const { items } = this.props;

    if (!items) return <React.Fragment />;

    return (
      <div className="selectedModel__tabs">
        {this.props.items.map((x, i) => (
          <div
            className={`selectedModel__tabs--item ${
              this.state.selected == i ? "selected" : ""
            }`}
            key={i}
          >
            <div
              className="selectedModel__tabs--title"
              onClick={() => this.selectTab(i)}
            >
              {x[0]}
              {this.state.selected == i ? (
                <FontAwesomeIcon
                  icon={faTimes}
                  className="tabs-icon"
                  color="red"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faPlus}
                  className="tabs-icon"
                  color="red"
                />
              )}
            </div>
            <div
              className={`selectedModel__tabs--content ${
                this.state.selected == i
                  ? "animate__animated animate__fadeIn"
                  : ""
              }`}
            >
              <pre dangerouslySetInnerHTML={{ __html: x[1] }} />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

@observer
class Select extends React.Component {
  store = new Store();

  componentDidMount() {
    this.store.getModels();
    // console.log(appConfig);
  }

  render() {
    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a href={appConfig.locale.url}>
              <img src={getLogo(appConfig.locale.key)} className="logo" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="modelSelector__left">
            <div className="modelSelector__title">
              Select your <strong>VanDutch</strong>
            </div>
            <div className="modelSelector__items">
              {this.store.list.map((x) => (
                <Model key={x.id} model={x} store={this.store} />
              ))}
            </div>
          </div>
          <div
            id="content"
            className={`modelSelector__right ${
              this.store.selectAnimate
                ? "animate__animated animate__fadeInRight"
                : ""
            }`}
          >
            {this.store.selectedModel ? (
              <div>
                <div
                  className="selectedModel__title"
                  dangerouslySetInnerHTML={{
                    __html: this.store.selectedModel.name,
                  }}
                ></div>
                <div className="selectedModel__tech">
                  <div className="selectedModel__subtitle">
                    <FontAwesomeIcon
                      className="indicator2"
                      icon={faChevronRight}
                      color="red"
                    />
                    <strong>TECHNICAL</strong> SPECIFICATIONS
                  </div>
                  <div className="selectedModel__specs">
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Length</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.length_m}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Beam</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.beam}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Draft</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.draft}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">
                        Displacement
                      </div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.displacement}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Passengers</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.passengers}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Berths</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.berths}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Fuel</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.fuel}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">
                        Fresh Water Cap.
                      </div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.water}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="selectedModel__standard">
                  <div className="selectedModel__subtitle">
                    <FontAwesomeIcon
                      className="indicator2"
                      icon={faChevronRight}
                      color="red"
                    />
                    <strong>STANDARD</strong> SPECIFICATIONS
                  </div>
                  <div className="selectedModel__standardspecs">
                    <Tabs items={this.store.selectedModel.tabs} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="modelSelector__rightLogo">
                <img src={Logo} width="250" />
              </div>
            )}
          </div>
        </div>
        <div className="footer modelSelector__footer">
          <div className="modelSelector__footer--left"></div>
          <div className="modelSelector__footer--right">
            {this.store.selectedModel && (
              <div className="modelSelector__button">
                <a href={`/build/${this.store.selectedModel.slug}`}>NEXT</a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Select;
