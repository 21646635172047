import React from "react";
import ReactDOM from "react-dom";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import Select from "./Select";
import * as serviceWorker from "./serviceWorker";
import { appConfig } from "./config";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      {appConfig.locale.multi_language ? (
        <Route path="/build/:lang/:slug" component={App} />
      ) : (
        <Route path="/build/:slug" component={App} />
      )}
      <Route path="/" exact component={Select} />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
