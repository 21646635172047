import React from "react";
import { observer } from "mobx-react";
import { appConfig } from "../config";
import { convertCurrency } from "../helpers";
import { Translation } from "../Translation";

@observer
class SummaryStep extends React.Component {
  render() {
    const { store } = this.props;

    return (
      <div className="step-summary-content">
        <div className="left">
          <div className="heading">
            <h1>
              <Translation
                lang={store.page.lang}
                t="summary_title"
                params={{ model: store.data.name }}
              />
            </h1>
          </div>

          <div
            className="image"
            style={{
              backgroundImage:
                store.data.picture &&
                `url(${appConfig.api}${store.data.picture.url})`,
            }}
          ></div>
        </div>
        <div className="options-wrapper">
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: store.data.name }}
          ></div>

          {/* <div className="standards-wrapper">
            <h3>
              <Translation lang={store.page.lang} t="summary_standards" />
            </h3>
            <div className="standards">
              <div className="standards-left">
                <pre>{store.data[store.langify("standards")]}</pre>
              </div>
              <div className="standards-right">
                <pre>{store.data[store.langify("standards_right")]}</pre>
              </div>
            </div>
          </div> */}
          <div className="upgrades-wrapper">
            <h3>
              <Translation lang={store.page.lang} t="summary_upgrade" />
            </h3>
            <div className="upgrades">
              {store.summary.upgradeOptions &&
                store.summary.upgradeOptions.map((option, i) => (
                  <div className="option" key={i}>
                    <span className="text">
                      {option[store.langify("title")]}
                    </span>
                    <span className="price">
                      {convertCurrency(option.price)}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="purchase-wrapper">
            <div className="purchase-price">
              <div className="purchase-price-text">
                <Translation lang={store.page.lang} t="footer_purchase_price" />
              </div>
              <div className="purchase-price-amount">
                {convertCurrency(store.summary.purchase_price)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryStep;
