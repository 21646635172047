import React from "react";
import Texty from "rc-texty";
import ClipLoader from "react-spinners/PropagateLoader";

export const Loading = () => {
  return (
    <div
      className="app"
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <div className="loading" style={{ fontSize: 30, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Texty>Loading...</Texty>
        <ClipLoader
          size={10}
          color={"#000000"}
          loading={true}
        />
      </div>
    </div>
  );
};
