// 1. Add Logo
import logo_florida from "./Logo/florida.svg";
import logo_sttropez from "./Logo/sttropez.svg";
import logo_turkey from "./Logo/turkey.svg";
import logo_northeast from "./Logo/northeast.svg";
import logo_vandutch from "./Logo/VanDutch.svg";

// import logo_new from "./Logo/new.svg";

// 2. Copy Locale
const locale = {
  local: {
    key: "local",
    name: "VanDutch USA",
    multi_language: false,
    has_payment: false,
    culture: "en-US",
    currency: "usd",
    symbol: "$",
    lang: "en",
    api: "http://localhost:8000",
    url: "http://localhost:3000",
  },
  vandutch: {
    key: "vandutch",
    name: "VanDutch USA",
    multi_language: false,
    has_payment: false,
    culture: "en-US",
    currency: "usd",
    symbol: "$",
    lang: "en",
    api: "https://api.vandutchusa.com",
    url: "https://configurator.vandutchusa.com",
  },
  theonexlv: {
    key: "theonexlv",
    name: "TheOne Marine Yachts",
    multi_language: false,
    has_payment: false,
    culture: "en-US",
    currency: "usd",
    symbol: "$",
    lang: "en",
    api: "https://api.theonemarineyachts.com",
    url: "https://configurator.theonemarineyachts.com",
  },
  turkey: {
    key: "turkey",
    name: "SaxDor Turkey",
    multi_language: true,
    has_payment: false,
    culture: "tr-TR",
    currency: "eur",
    symbol: "€",
    lang: "tr",
    api: "https://api.saxdorturkey.com",
    url: "https://saxdorturkey.com",
  },
  florida: {
    key: "florida",
    name: "SaxDor Florida",
    multi_language: false,
    has_payment: true,
    culture: "en-US",
    currency: "usd",
    symbol: "$",
    lang: "en",
    api: "https://apisaxdor.saxdorturkey.com",
    url: "https://saxdorflorida.com",
  },
  sttropez: {
    key: "sttropez",
    name: "SaxDor St. Tropez",
    multi_language: false,
    has_payment: false,
    culture: "en-EN",
    currency: "eur",
    symbol: "€",
    lang: "en",
    api: "https://api.saxdorsttropez.com",
    url: "https://saxdorsttropez.com",
  },
  northeast: {
    key: "northeast",
    name: "SaxDor Northeast",
    multi_language: false,
    has_payment: true,
    culture: "en-US",
    currency: "usd",
    symbol: "$",
    lang: "en",
    api: "https://api.saxdornortheast.com",
    url: "https://saxdornortheast.com",
  },
  // new: {
  //   key: 'new',
  //   name: "SaxDor New",
  //   multi_language: false,
  //   has_payment: true,
  //   culture: "en-US",
  //   currency: "usd",
  //   symbol: '$',
  //   lang: "en",
  //   api: "https://api.saxdornew.com",
  //   url: "https://saxdornew.com"
  // },
};

let { REACT_APP_LOCALE } = process.env;
if (!REACT_APP_LOCALE) REACT_APP_LOCALE = "local";

const selected_locale = locale[REACT_APP_LOCALE];

export const getLogo = (lang) => {
  // 3. Copy logo
  if (lang == "local") return logo_vandutch;
  if (lang == "florida") return logo_florida;
  if (lang == "turkey") return logo_turkey;
  if (lang == "sttropez") return logo_sttropez;
  if (lang == "northeast") return logo_northeast;
  if (lang == "vandutch") return logo_vandutch;
  if (lang == "theonexlv") return logo_vandutch;
  // if(lang == "new") return logo_new;
  return undefined;
};

export const appConfig = {
  api: selected_locale.api,
  locale: selected_locale,
};

// 4. package.json copy start and run
/*
"start:new": "REACT_APP_LOCALE=new npm start",
"build:new": "REACT_APP_LOCALE=new npm run build -- --prod && rm -rf build_new && mv build build_new && open build_new",
*/
