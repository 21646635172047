import React from "react";
import { observer } from "mobx-react";
import { appConfig } from "../config";
import { convertCurrency } from "../helpers";
import { toJS } from "mobx";

@observer
class PaymentStep extends React.Component {
  get pdfURL() {
    const { store } = this.props;

    if (store.data.name.includes("S 200 Pro Sport"))
      return "/wp-content/uploads/SaxDor_S200_PRO_SPORT_Order_Agreement_2020.pdf";

    if (store.data.name.includes("S 200 Sport"))
      return "/wp-content/uploads/SaxDor_S200_Order_Agreement_2020.pdf";

    if (store.data.name.includes("S 320 GTR"))
      return "/wp-content/uploads/SaxDor_S320__Order_Agreement_2020.pdf";

    if (store.data.name.includes("S 320 GTC"))
      return "/wp-content/uploads/SaxDor_S320__Order_Agreement_2020.pdf";

    if (store.data.name.includes("S 320 GTO"))
      return "/wp-content/uploads/SaxDor_S320__Order_Agreement_2020.pdf";

    return "";
  }

  render() {
    const { store } = this.props;

    return (
      <div className="step-payment-content">
        <div className="left">
          <h2>My Details</h2>

          <form onSubmit={store.onPaymentSubmit}>
            <div
              className="form"
              style={{ borderBottom: "1px solid", paddingBottom: "1rem" }}
            >
              <div className="form-item">
                <label>First Name</label>
                <input
                  required
                  type="text"
                  name="firstname"
                  value={store.detailsForm.firstname}
                  onChange={(e) =>
                    (store.detailsForm.firstname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Last Name</label>
                <input
                  required
                  type="text"
                  name="lastname"
                  value={store.detailsForm.lastname}
                  onChange={(e) =>
                    (store.detailsForm.lastname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Email Address</label>
                <input
                  required
                  type="email"
                  name="email"
                  value={store.detailsForm.email}
                  onChange={(e) => (store.detailsForm.email = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Phone Number</label>
                <input
                  required
                  type="text"
                  name="phone"
                  value={store.detailsForm.phone}
                  onChange={(e) => (store.detailsForm.phone = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Where are you boating?</label>
                <input
                  type="text"
                  name="boating"
                  value={store.detailsForm.boating}
                  onChange={(e) => (store.detailsForm.boating = e.target.value)}
                />
              </div>
            </div>

            <h2>Payment</h2>

            {store.page.error && (
              <div className="error">{store.page.error}</div>
            )}

            <div className="form">
              <div className="form-item">
                <label>Name on Card</label>
                <input
                  required
                  type="text"
                  name="name"
                  value={store.paymentForm.name}
                  onChange={(e) => (store.paymentForm.name = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Credit Card Number</label>
                <input
                  required
                  type="text"
                  name="lastname"
                  value={store.paymentForm.cardnumber}
                  onChange={(e) =>
                    (store.paymentForm.cardnumber = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Exp. Month</label>
                <input
                  required
                  type="text"
                  name="exp_month"
                  value={store.paymentForm.exp_month}
                  onChange={(e) =>
                    (store.paymentForm.exp_month = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Exp. Year</label>
                <input
                  required
                  type="text"
                  name="exp_year"
                  value={store.paymentForm.exp_year}
                  onChange={(e) =>
                    (store.paymentForm.exp_year = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>CVV</label>
                <input
                  required
                  type="text"
                  name="cvv"
                  value={store.paymentForm.cvv}
                  onChange={(e) => (store.paymentForm.cvv = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Billing zip code</label>
                <input
                  type="text"
                  name="zipcode"
                  value={store.paymentForm.zipcode}
                  onChange={(e) => (store.paymentForm.zipcode = e.target.value)}
                />
              </div>
            </div>

            <div className="agreement-text">
              By clicking "Place Order" I agree to{" "}
              <a href={this.pdfURL}>{store.data.name} Order</a> Agreement, the
              Customer Privacy Agreement <br />
              and consent to be contacted at the phone number provided with more
              information or offers about {appConfig.locale.name} products.{" "}
              <br />I understand my consent to be contacted is not a condition
              of purchase.
            </div>

            <div style={{ textAlign: "right", marginTop: 50 }}>
              <button type="submit" className="button red-button wide">
                PLACE ORDER {convertCurrency(store.data.due_today_price)}
              </button>
            </div>
          </form>
        </div>
        <div className="options-wrapper">
          <img
            className="image"
            src={
              store.data.picture
                ? `${appConfig.api}${store.data.picture.url}`
                : "https://via.placeholder.com/150"
            }
          />

          <h2>{store.data.name}</h2>

          {/* <div className="standards-wrapper">
            <h3>STANDARDS</h3>
            <div className="standards">
              <div className="standards-left">
                <pre>{store.data.standards}</pre>
              </div>
              <div className="standards-right">
                <pre>{store.data.standards_right}</pre>
              </div>
            </div>
          </div> */}
          <div className="upgrades-wrapper">
            <h3>UPGRADES & OPTIONS</h3>
            <div className="upgrades">
              {store.summary.upgradeOptions && store.summary.upgradeOptions.map((option, i) => (
                <div className="option" key={i}>
                  <span className="text">{option.title}</span>
                  <span className="price">{convertCurrency(option.price)}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="purchase-wrapper">
            <div className="purchase-price">
              <div className="purchase-price-text">PURCHASE PRICE</div>
              <div className="purchase-price-amount">
                {convertCurrency(store.summary.purchase_price)}
              </div>
            </div>
            <div className="purchase-price duetoday">
              <div className="purchase-price-text">DUE TODAY</div>
              <div className="purchase-price-amount">
                {convertCurrency(store.data.due_today_price)}
              </div>
            </div>
            <div className="purchase-footer-text">
              Non-Refundable Order Fee.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentStep;
