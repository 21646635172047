import React from "react";
import { observer } from "mobx-react";
import { appConfig } from "../config";
import { convertCurrency, datePicker } from "../helpers";
import { toJS } from "mobx";
import { Translation } from "../Translation";

@observer
class PdfStep extends React.Component {
  dates = datePicker();

  render() {
    const { store } = this.props;

    return (
      <div className="step-payment-content" id="pdf-step">
        <div className="left">
          <h2>
            <Translation lang={store.page.lang} t="form_details" />
          </h2>

          <form id="pdf_form" onSubmit={store.onPdfSubmit}>
            <div className="form">
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_first_name" />
                </label>
                <input
                  required
                  type="text"
                  name="firstname"
                  value={store.detailsForm.firstname}
                  onChange={(e) =>
                    (store.detailsForm.firstname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_last_name" />
                </label>
                <input
                  required
                  type="text"
                  name="lastname"
                  value={store.detailsForm.lastname}
                  onChange={(e) =>
                    (store.detailsForm.lastname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_email" />
                </label>
                <input
                  required
                  type="email"
                  name="email"
                  value={store.detailsForm.email}
                  onChange={(e) => (store.detailsForm.email = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_phone" />
                </label>
                <input
                  required
                  type="text"
                  name="phone"
                  value={store.detailsForm.phone}
                  onChange={(e) => (store.detailsForm.phone = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_boating" />
                </label>
                <input
                  type="text"
                  name="boating"
                  value={store.detailsForm.boating}
                  onChange={(e) => (store.detailsForm.boating = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Preffered Delivery Date</label>
                <select
                  value={store.detailsForm.delivery}
                  onChange={(e) =>
                    (store.detailsForm.delivery = e.target.value)
                  }
                >
                  <option></option>
                  {this.dates.map((d, i) => (
                    <option value={d.value} key={i}>
                      {d.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: 50 }}>
              {/* <div className="modelSelector__button">
                <a type="submit" onClick={() => { document.querySelector("#pdf_form").submit()}}>
                  <Translation t="button_pdf" lang={store.page.lang} />
                </a>
              </div> */}

              <button type="submit" className="button red-button wide">
                <Translation lang={store.page.lang} t="button_pdf" />
              </button>
            </div>
          </form>
        </div>
        <div className="options-wrapper">
          {store.data.picture && (
            <img
              className="image"
              src={`${appConfig.api}${store.data.picture.url}`}
            />
          )}
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: store.data.name }}
          ></div>

          {/* <div className="standards-wrapper">
            <h3>
              <Translation lang={store.page.lang} t="summary_standards" />
            </h3>
            <div className="standards">
              <div className="standards-left">
                <pre>{store.data[store.langify("standards")]}</pre>
              </div>
              <div className="standards-right">
                <pre>{store.data[store.langify("standards_right")]}</pre>
              </div>
            </div>
          </div> */}
          <div className="upgrades-wrapper">
            <h3>
              <Translation lang={store.page.lang} t="summary_upgrade" />
            </h3>
            <div className="upgrades">
              {store.summary.upgradeOptions &&
                store.summary.upgradeOptions.map((option, i) => (
                  <div className="option" key={i}>
                    <span className="text">
                      {option[store.langify("title")]}
                    </span>
                    <span className="price">
                      {convertCurrency(option.price)}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="purchase-wrapper">
            <div className="purchase-price">
              <div className="purchase-price-text">
                <Translation lang={store.page.lang} t="footer_purchase_price" />
              </div>
              <div className="purchase-price-amount">
                {convertCurrency(store.summary.purchase_price)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PdfStep;
