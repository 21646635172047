import React from "react";
import { convertCurrency } from "../helpers";

export const ZohoForm = (props) => {
  React.useEffect(() => {
    setTimeout(() => {
      document.querySelector("#WebForm3768811000027620453").submit();
    }, 100);
  }, []);

  return (
    <div
      id="crmWebToEntityForm"
      className="zcwf_lblLeft crmWebToEntityForm"
      style={{ display: "none" }}
    >
      <form
        action="https://crm.zoho.com/crm/WebForm"
        name="WebForm3768811000027620453"
        id="WebForm3768811000027620453"
        method="POST"
        encType="multipart/form-data"
        acceptCharset="UTF-8"
      >
        <input
          type="text"
          name="xnQsjsdp"
          onChange={() => { }}
          value="9626195239847f04c340ccbdcdb82c3f0b06d22e4dbc684e89114dfbec9d7230"
        />
        <input type="hidden" name="zc_gad" id="zc_gad" value="" />
        <input
          type="text"
          name="xmIwtLD"
          onChange={() => { }}
          value="a7b1223fe1457bcff7ab14b000354eedc84bdce6733418be13bdc50bbf094e35"
        />
        <input
          type="text"
          name="actionType"
          onChange={() => { }}
          value="Q3VzdG9tTW9kdWxlMTk="
        />
        <input
          type="text"
          name="returnURL"
          onChange={() => { }}
          value="https&#x3a;&#x2f;&#x2f;configurator.vandutchusa.com&#x2f;success.html"
        />
        <div className="zcwf_title">VandutchUsa Configurator</div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="COBJ19CF8">Model</label>
          </div>
          <div className="zcwf_col_fld">
            <select
              className="zcwf_col_fld_slt"
              id="COBJ19CF8"
              name="COBJ19CF8"
              onChange={() => { }}
            >
              <option value="-None-">-None-</option>
              <option value="VanDutch&#x20;30">VanDutch 30</option>
              <option value="VanDutch&#x20;40">VanDutch 40</option>
              <option value="VanDutch&#x20;48">VanDutch 48</option>
              <option value="VanDutch&#x20;56">VanDutch 56</option>
              <option value="VanDutch&#x20;75">VanDutch 75</option>
            </select>
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="COBJ19CF19">First Name</label>
          </div>
          <div className="zcwf_col_fld">
            <input
              type="text"
              id="COBJ19CF19"
              name="COBJ19CF19"
              maxLength="255"
              value={props.data.form.detailsForm.firstname}
              onChange={() => { }}
            />
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="NAME">
              Last Name<span>*</span>
            </label>
          </div>
          <div className="zcwf_col_fld">
            <input
              type="text"
              id="NAME"
              name="NAME"
              maxLength="120"
              onChange={() => { }}
              value={props.data.form.detailsForm.lastname}
            />
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="COBJ19CF16">Lead Source</label>
          </div>
          <div className="zcwf_col_fld">
            <input
              type="text"
              id="COBJ19CF16"
              name="COBJ19CF16"
              onChange={() => { }}
              value={"Website"}
            />
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="COBJ19CF15">Lead Source Name</label>
          </div>
          <div className="zcwf_col_fld">
            <input
              type="text"
              id="COBJ19CF15"
              name="COBJ19CF15"
              maxLength="255"
              onChange={() => { }}
              value="Configurator"
            />
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="Email">Email</label>
          </div>
          <div className="zcwf_col_fld">
            <input
              type="text"
              ftype="email"
              id="Email"
              name="Email"
              maxLength="100"
              onChange={() => { }}
              value={props.data.form.detailsForm.email}
            />
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="COBJ19CF12">Phone</label>
          </div>
          <div className="zcwf_col_fld">
            <input
              type="text"
              id="COBJ19CF12"
              name="COBJ19CF12"
              maxLength="30"
              onChange={() => { }}
              value={props.data.form.detailsForm.phone}
            />
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="COBJ19CF7">Where are you Boating &#x3f;</label>
          </div>
          <div className="zcwf_col_fld">
            <input
              type="text"
              id="COBJ19CF7"
              name="COBJ19CF7"
              maxLength="255"
              onChange={() => { }}
              value={props.data.form.detailsForm.boating}
            />
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="COBJ19CF37">Delivery Date</label>
          </div>
          <div className="zcwf_col_fld">
            <input
              type="text"
              id="COBJ19CF37"
              name="COBJ19CF37"
              maxLength="255"
              onChange={() => { }}
              value={props.data.form.detailsForm.delivery}
            />
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">PDF Link</div>
          <div className="zcwf_col_fld">
            <div className="clearB">
              <input type='text' id='COBJ19CF39' name='COBJ19CF39' maxlength='450' onChange={() => { }} value={props.data.pdf_path} />
              <div className="zcwf_col_help"></div>
            </div>
          </div>
        </div>
        <div className="zcwf_row">
          <div className="zcwf_col_lab">
            <label htmlFor="COBJ19CF38">Summary</label>
          </div>
          <div className="zcwf_col_fld">
            <textarea
              id="COBJ19CF38"
              name="COBJ19CF38"
              onChange={() => { }}
              value={props.data.upgradeOptions.map(
                (option) => `${option.title} ${convertCurrency(option.price)}\n`
              )}
            ></textarea>
            <div className="zcwf_col_help"></div>
          </div>
        </div>
        <button>Submit</button>
      </form>
    </div>
  );
};
