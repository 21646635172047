import React from "react";
import { appConfig } from "../config";
import { convertCurrency } from "../helpers";
import { observer } from "mobx-react";

@observer
class OptionWithImage extends React.Component {
  render() {
    const { type, option, group, store } = this.props;

    return (
      <div
        className={`option ${option.package ? "option-package" : ""} ${
          type == "singleImage" ? "option-radio-image" : "option-checkbox-image"
        } ${option.selected ? "option-selected" : ""}`}
        style={{ display: option.hidden ? "none" : "" }}
        onClick={() => {
          store.selectOption(option, group);
        }}
        disabled={option.package}
      >
        <div className="option-inner">
          <div className="option-image">
            <img src={`${appConfig.api}${option.picture.url}`} />
          </div>
          <div className="option-title">{option[store.langify("title")]}</div>
          {option.description && (
            <div className="option-description">
              ({option[store.langify("description")]})
            </div>
          )}
          {option.package_name && (
            <div className="option-package-name">({option.package_name})</div>
          )}
          {option.price && (
            <div className="option-price">
              {convertCurrency(option.price, true)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

@observer
class OptionWithoutImage extends React.Component {
  render() {
    const { type, option, group, store } = this.props;

    return (
      <div
        className={`option ${option.package ? "option-package" : ""} ${
          type == "single" ? "option-radio" : "option-checkbox"
        } ${option.selected ? "option-selected" : ""}`}
        style={{ display: option.hidden ? "none" : "" }}
        onClick={() => {
          store.selectOption(option, group);
        }}
      >
        <div className="option-inner">
          <div className="option-title">{option[store.langify("title")]}</div>
          <div className="option-description">
            <pre>{option[store.langify("description")]}</pre>
          </div>
          {option.package_name && (
            <div className="option-package-name">({option.package_name})</div>
          )}
          <div className="option-price">{convertCurrency(option.price)}</div>
        </div>
      </div>
    );
  }
}

@observer
class GridStep extends React.Component {
  render() {
    const { step, store } = this.props;

    return (
      <div className="step-grid-content">
        {step.option_groups.map((group, i) => (
          <div className={`option-group ${group.type}`} key={i}>
            {group.title && (
              <div className="option-group-title">
                {group[store.langify("title")]}
              </div>
            )}
            <div
              className="option-group-options"
              style={{
                gridTemplateColumns: `repeat(${group.grid_size}, minmax(0, 1fr))`,
              }}
            >
              {group.options.map((option, j) =>
                group.type == "multiImage" ? (
                  <OptionWithImage
                    key={j}
                    type={group.type}
                    group={group}
                    option={option}
                    store={store}
                  />
                ) : (
                  <OptionWithoutImage
                    key={j}
                    type={group.type}
                    group={group}
                    option={option}
                    store={store}
                  />
                )
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default GridStep;
