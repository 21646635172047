import { observable, action, toJS, computed } from "mobx";
import Axios from "axios";
import { appConfig } from "./config";

export class Store {
  @observable list = [];

  @observable selectedModel = undefined;

  @observable selectAnimate = false;

  @observable
  page = {
    lang: "en",
    suffix: "",
    loading: true,
    selectedStep: null,
    completed: false,
    completionType: "", // pdf | payment
    stepIndex: 0,
    summaryStep: 0,
    pdfStep: 0,
    paymentStep: 0,
    completeStep: 0,
    error: "",
    hideSteps: false,
    order_number: "",
    original_picture: undefined,
    pdf_path: undefined,
  };

  @observable
  detailsForm = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    boating: "",
    delivery: "",
  };

  @observable
  paymentForm = {
    name: "",
    cardnumber: "",
    exp_month: "",
    exp_year: "",
    cvv: "",
    zipcode: "",
  };

  @observable lastPrice = 0;

  @observable data = { steps: [] };

  @observable state = "configurator"; // configurator | summary | payment | pdf

  @action getModels = () => {
    Axios.get(`${appConfig.api}/models`)
      .then((r) => (this.list = r.data))
      .catch((e) => console.log(e));
  };

  @action selectModel = (model) => {
    if (this.selectedModel && model.id == this.selectedModel.id) return;
    this.selectAnimate = true;
    this.selectedModel = model;

    if (this.selectedModel.standards_rich) {
      try {
        var parts = this.selectedModel.standards_rich.split(">>");
        var items = [];

        parts.map((x) => {
          var xxx = x.split("--");
          items.push([xxx[0].replaceAll("\n", ""), xxx[1].replace("\n", "")]);
        });

        this.selectedModel.tabs = items;
      } catch { }
    }

    setTimeout(() => (this.selectAnimate = false), 1000);
  };

  @action
  init = (slug, lang) => {
    this.page.lang = lang ? lang : appConfig.locale.lang;

    if (this.page.lang != "en") this.page.suffix = `_${lang}`;

    Axios.get(
      `${appConfig.api}/models/byslug/${slug}` +
      "?nocache=" +
      new Date().getTime()
    )
      .then((r) => {
        this.data = r.data;

        this.data.steps.map((step) => {
          step.option_groups.map((option_group) => {
            if (option_group.has_additional) {
              option_group.options.push({
                id: 9999,
                selected: false,
                title: "",
                additional: true,
                price: {
                  usd: 1,
                },
              });
            }
          });
        });
        
        this.page.loading = false;
        this.page.hideSteps = false;
        this.page.summaryStep = r.data.steps.length;
        this.page.pdfStep = r.data.steps.length + 1;
        this.page.paymentStep = r.data.steps.length + 2;
        this.page.completeStep = r.data.steps.length + 3;
        this.page.original_picture = r.data.picture;

        if (slug.includes("inventory")) {
          this.page.stepIndex = r.data.steps.length;
          this.page.hideSteps = true;
        } else {
          this.page.stepIndex = 0;
        }
      })
      .catch((e) => { });
  };

  @action
  onStepSelected = (step, i) => {
    this.page.stepIndex = i;
    this.page.selectedStep = step;

    setTimeout(() => {
      if (step.target) {
        document.getElementById(step.target).scrollIntoView();
      }
    }, 200);
  };

  @action
  nextStep = () => {
    this.page.stepIndex++;
  };

  @action
  onPdfSubmit = (e) => {
    e.preventDefault();

    this.page.loading = true;
    this.page.error = "";

    const url = this.langify("/payments/pdf");

    Axios.post(
      `${appConfig.api}${url}` + "?nocache=" + new Date().getTime(),
      this.summary
    )
      .then((r) => {
        this.page.loading = false;
        this.page.order_number = r.data.order_number;
        this.page.pdf_path = r.data.pdf;

        this.page.completed = true;
        this.page.completionType = "pdf";
        this.onStepSelected({}, this.page.completeStep);
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action
  onPaymentSubmit = (e) => {
    e.preventDefault();

    this.page.loading = true;
    this.page.error = "";

    Axios.post(
      `${appConfig.api}/payments/make` + "?nocache=" + new Date().getTime(),
      this.summary
    )
      .then((r) => {
        this.page.loading = false;
        this.page.order_number = r.data;

        if (r.data.stripe_error) {
          this.page.error = "Payment failed: " + r.data.stripe_error;
          return;
        }

        this.page.completed = true;
        this.page.completionType = "payment";
        this.onStepSelected({}, this.page.completeStep);
      })
      .catch((e) => {
        this.page.loading = false;
        console.log(e);
      });
  };

  @action
  selectOption = (option, group, required = false) => {
    if (group.required && option.selected) return;

    if (option.package) return;

    this.lastPrice = this.summary.purchase_price;

    if (group.type == "single" || group.type == "singleImage") {
      if (!option.selected) {
        group.options.forEach((xo) => {
          xo.selected = false;
        });
        option.selected = true;
      } else {
        if (!required) option.selected = false;
      }

      if (option.related) {
        var step = this.data.steps.find((x) => x.id == group.step);
        var ogs = step.option_groups.find(
          (x) => x.id == option.related.option_group
        );

        if (ogs) {
          ogs.options.forEach((x) => (x.selected = false));
        }
      }
    } else {
      option.selected = !option.selected;
    }

    var upgradeOptions = this.data.steps.flatMap((x) =>
      x.option_groups.flatMap((og) => og.options)
    );

    if (option.package_content) {
      for (var i = 1; i < upgradeOptions.length; i++) {
        var uoption = upgradeOptions[i];

        if (
          option.package_content.package_content_items.some(
            (x) => x.id == uoption.id
          )
        ) {
          uoption.selected = false;
          uoption.package = option.selected;
          uoption.package_name = option.selected
            ? option[this.langify("title")]
            : undefined;
        }
      }
    }

    if (option.cover_picture) {
      if (option.selected) {
        this.data.picture = option.cover_picture;
      } else {
        this.data.picture = this.page.original_picture;
      }
    }
  };

  langify = (key) => {
    return `${key}${this.page.suffix}`;
  };

  @computed
  get summary() {
    const data = toJS(this.data);

    const result = {
      locale: appConfig.locale,
      model: {
        id: data.id,
        title: data.name,
        picture: data.cover,
        selected_picture: data.picture
          ? appConfig.locale.api + data.picture.url
          : undefined,
        standards: data.standards,
        standards_right: data.standards_right,
        due_today_price: data.due_today_price,
      },
      pdf_path: this.page.pdf_path,
      purchase_price: 0,
      due_today_price: this.data.due_today_price,
      refundable: false,
      type: null, // pdf | purchase
      form: {
        upgradeOptions: [],
        paymentForm: toJS(this.paymentForm),
        detailsForm: toJS(this.detailsForm),
      },
    };

    if (data.steps.length > 0) {
      result.upgradeOptions = data.steps.flatMap((x) =>
        x.option_groups.flatMap((og) =>
          og.options
            .filter((o) => o.selected)
            .map((oo) => ({ ...oo, title: `${og.title} - ${oo.title}` }))
        )
      );

      if (result.upgradeOptions.length > 0) {
        result.purchase_price = result.upgradeOptions.reduce((a, b) => {
          return (
            a +
            (b.price != null
              ? b.price[appConfig.locale.currency] > 0
                ? b.price[appConfig.locale.currency]
                : 0
              : 0)
          );
        }, 0);
      }
    }

    return result;
  }
}
