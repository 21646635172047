import moment from "moment";
import { appConfig } from "./config";

export const convertCurrency = (price, hasPlus) => {
  if (typeof price == "object") {
    if (!price) return "Included";

    price = price[appConfig.locale.currency];

    if (price == -1) return "TBA";
    if (price == -2) return "Per Design";
    if (price == -3) return "Select Lounge Layout";
    if (price == -4) return "Included";
    if (!price) return "Included";
  }

  const currency = new Intl.NumberFormat(appConfig.culture, {
    style: "currency",
    currency: appConfig.locale.currency,
  }).format(price);

  return hasPlus ? `+ ${currency}` : currency;
};

export const datePicker = () => {
  var dates = [];

  for (var i = 1; i < 25; i++) {
    var date = moment().date(1).add(i, "months");
    dates.push({ value: date.format("MM/DD/YYYY"), text: date.format("MMMM, YYYY") });
  }

  return dates;
};
